@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.wrapper {
  &_content {
    @include display(flex, column);
    max-width: 1600px;
    margin: 0 auto;
    gap: 1rem;

    p {
      @include font(400, $font-default-size);
    }

    &_body {
      @include display(flex, column);

      &_dealerData {
        @include display(flex, column);
        width: 100%;
        gap: 2rem;

        h2 {
          padding: 0 1rem;
        }

        &_dealerBranch {
          &_first,
          &_second {
            @include display(flex, column);
            padding: 2rem 1rem;

            &_details {
              @include display(flex, row);
              flex-wrap: wrap;
              gap: 20px;

              @media (max-width: 1224px) {
                gap: 1rem;
              }

              &_workingHours,
              &_services,
              &_contacts {
                min-width: 200px;
                h4 {
                  @include font(bold, $font-default-size);
                  padding-bottom: 0.5rem;
                }
                @include display(flex, column);
              }
            }

            span {
              padding-bottom: 1rem;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              @include font(bold, $font-default-size);

              img {
                max-width: 300px;
                @media (max-width: 1224px) {
                  max-width: 100%;
                }
              }
            }
          }

          &_first {
            background-color: rgba(black, 0.04);
          }

          &_second {
            background: transparent;
          }
        }
      }

      h2 {
        @include font(bold, $font-subtitle-size);
      }

      span {
        @include font(400, $font-default-size);
      }
      &_dealerDescription {
        padding: 0 1rem 2rem;
        
        @media (max-width: 1224px) {
          text-align: justify;
        }
      }
    }

    &_title {
      padding: 1rem;
      display: flex;
      display: -moz-flex;
      justify-content: center;
      justify-content: -moz-center;
      align-items: center;
      align-items: -moz-center;
      text-align: center;

      h1 {
        font-size: $font-title-size;
        font-weight: bold;
        border-bottom: 3px solid #cf2626;
      }
    }
  }
}
