@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.wrapper {
  ul {
    outline: 0;
    font-size: 100%;
    background: transparent;
  }

  &_content {
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem 5rem;
    @include display(flex, column, center, center);
    gap: 2rem;

    @media (max-width: 1224px) {
      padding: 2rem 1rem;
    }

    &_title {
      @include display(flex, column, center, center);
      h1 {
        @include font(bold, $font-title-size);
        border-bottom: 3px solid #cf2626;
      }
    }

    &_buttonsBlock {
      width: 100%;
      margin: 0 -20px;
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      flex-wrap: nowrap;
      gap: 2rem;

      &_button {
        width: 33.3333333%;
        box-sizing: border-box;

        &_linkWrapper {
          display: block;
          width: 100%;
          padding-top: 56.25%;
          overflow: hidden;
          position: relative;
          text-decoration: none;
          color: inherit;
          border-radius: 10px;

          &:hover {
            img {
              transform: scale(1.07);
            }
          }

          img {
            object-fit: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
            transition:
              transform 420ms cubic-bezier(0.75, 0.02, 0.5, 1),
              -webkit-transform 420ms cubic-bezier(0.75, 0.02, 0.5, 1);

            &:hover {
              transform: scale(1.1);
            }
          }

          &:visited {
            text-decoration: none;
          }
        }

        &_textWrapper {
          background: rgba(0, 0, 0, 0.4);
          font-size: $font-small-size;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transition: all 0.3s ease 0s;
          text-align: center;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-align-items: center;
          align-items: center;

          p {
            color: #fff;
            font-weight: bold;
            font-size: $font-subtitle-size;
          }
        }
      }
    }
  }
}

@media (max-width: 1224px) {
  .wrapper_content_buttonsBlock {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    &_button {
      width: 100%;
      padding: 0;
      box-sizing: border-box;
    }
  }
}
