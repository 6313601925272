@import "../../../scss/mixin.scss";
@import "../../../scss/variable.scss";

.button {
  font-weight: 600;
  font-size: $font-default-size;
  padding: 0.5rem 1rem 0.5rem;
  color: white;
  background-color: #cf2626;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease-in 0.4s;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background-color: rgba(208, 37, 37, 0.9);
  }
}
