@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.carsPageWrapper {
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;
  transition: ease-in 0.3s;

  &_content {
    @include display(flex, column, flex-start);
    max-width: 1600px;
    padding: 2rem 5rem;
    margin: 0 auto;

    &_title {
      @include display(flex, column, center, center);
      gap: 20px;
      h1 {
        margin: 0 1rem;
        @include font(bold, $font-title-size);
        border-bottom: 3px solid #cf2626;
      }
    }

    &_container {
      @include display(flex, row, flex-start);
      padding: 2rem 0;
      gap: 50px;

      &_leftNavMenu {
        background: #f7f7f7;
        width: 310px;
        min-width: 310px;
        border-radius: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        height: fit-content;

        &_title {
          border-radius: 10px 10px 0 0;
          font-size: $font-subtitle-size;
          display: flex;
          font-weight: bold;
          color: #fff;
          height: 4rem;
          line-height: 4rem;
          padding: 0 0 0 2rem;
          background: #cf2626;
          position: relative;
        }

        &_category {
          @include font(400, $font-default-size);
          border-top: 1px solid #ededed;
          padding: 0.8rem 0.8rem 0.8rem 2rem;
          list-style: none;
          cursor: pointer;
          transition: ease-in 0.3s;

          @media (min-width: 1224px) {
            &:hover {
              color: #cf2626;
              background: #ffffff;
            }
          }
        }
      }

      &_rightModelsBlock {
        @include display(flex, row, flex-start);
        gap: 2rem;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 1224px) {
  .carsPageWrapper {
    &_content {
      padding: 2rem 1rem;

      &_title {
        h1 {
          text-align: center;
        }
        span {
          padding: 0 1rem;
          text-align: justify
        }
      }

      &_container {
        @include display(flex, column, center, center);
        gap: 2rem;
        padding: 2rem 1rem;

        &_leftNavMenu {
          width: 100%;
        }

        &_rightModelsBlock {
          @include display(flex, row, center, center);
          width: 100%;
        }
      }
    }
  }
}

.active {
  color: #cf2626;
  background: #ffffff;
}
