@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";
@import "./customArrows.css";

.wrapper {
  width: 100dvw;
  @include display(flex, column);

  &_main {
    @include display(flex, column, center, center);
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    gap: 50px;
    padding: 2rem 5rem;

    &_preview {
      @include display(flex, row, center);
      width: 100%;
      gap: 50px;

      &_swiper {
        max-width: calc(50% - 3rem);
      }

      &_shortDescription {
        @include display(flex, column, flex-start);
        width: 100%;

        hr {
          background: url("https://www.shineray.com/template/pc/default/images/line2.png")
            repeat-x;
          height: 4px;
          margin: 15px 0;
          opacity: 0.2;
        }

        &_title {
          font-size: $font-title-size;
          font-weight: 800;
        }

        &_tech {
          font-size: $font-default-size;
          font-weight: 400;
        }
      }
    }

    &_proText {
      @include display(flex, column, center, center);
      text-align: center;

      &_title {
        font-size: $font-subtitle-size;
        font-weight: bold;
        border-bottom: 3px solid #cf2626;
        // color: #a02a2a;
        text-align: center;
      }

      &_description {
        font-size: $font-default-size;
        font-weight: 400;
        margin: 1rem 0 0;
        text-align: start;
        // border-left: 3px double rgb(193, 193, 193);
      }
    }

    &_beautifulSlides {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 1rem;

      &_title {
        font-size: $font-subtitle-size;
        font-weight: bold;
        padding: 0.3rem 0;
        border-bottom: 3px solid #cf2626;
      }
    }
  }
}

.swiperStyle {
  @include display(flex, center, center);
}

.thumbsWrapper {
  transition: ease-in 0.3s;
  .swiper-wrapper,
  div {
    @include display(flex, row, center);
    justify-content: space-between;
  }

  &_thumbs {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
  }
}

.active {
  border: 1px solid rgba(193, 193, 193, 0.6);
}

@media (max-width: 1024px) {
  .wrapper {
    &_main {
      @include display(flex, column);
      padding: 2rem 2rem;

      &_preview {
        flex-direction: column;

        &_swiper {
          max-width: calc(100vw - 4rem);
        }

        &_shortDescription {
          margin: 0;
          min-width: 100%;
          h4 {
            text-align: center;
          }
          &_title {
            text-align: center;
          }
        }
      }
      &_proText {
        padding: 0;
        &_title {
          @include font(800, $font-title-size);
        }
      }
    }
  }
}

.engines {
  @include display(flex, column, flex-start, stretch);
  padding: 0 5px;
}

.techButton {
  margin-top: 2rem;
  max-width: fit-content;
}
