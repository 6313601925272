.swiper-button-prev::after {
  transition: ease-in 0.3s;
  color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);
}

.swiper-button-next::after {
  transition: ease-in 0.3s;
  color: rgba(255, 255, 255, 0.55);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  position: absolute;
  width: 60px !important;
  height: 60px !important;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 50%;
}

@media (max-width: 1224px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: small !important;
  }
}
