.breadcrumbs {
  display: flex;
  flex-direction: row;
  background: #fafafa;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
  padding: 0.5rem 3rem;

  @media (max-width: 1224px) {
    padding: 0.3rem 1rem;
  }
}
