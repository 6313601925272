.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: ease-in 0.3s;

  &_btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }

  &_btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }

  &_btnClose {
    top: 40px;
    right: 40px;
  }

  &_btnClose,
  &_btnNext,
  &_btnPrev {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    font-size: 2.5rem !important;
    z-index: 99;
    transition: ease-in 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &_fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;

    &_image {
      border-radius: 10px;
      z-index: 100;
      cursor: zoom-out;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.map {
  overflow: hidden;
}
