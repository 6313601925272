@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.wrapper {
  &_content {
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem 5rem;
    @include display(flex, column, center, center);
    gap: 2rem;

    @media (max-width: 1224px) {
      padding: 2rem 1rem;
    }

    &_title {
      @include display(flex, column, center, center);
      text-align: center;
      h1 {
        @include font(bold, $font-title-size);
        border-bottom: 3px solid #cf2626;
        text-align: center
      }
    }

    &_subtitle {
      @include display(flex, column, center, center);
      h1 {
        @include font(bold, $font-title-size);
        border-bottom: 3px solid #cf2626;

        @media (max-width: 1224px) {
          border: none;
        }
      }
    }

    &_docs {
      width: 100%;
      @include display(flex, column);
      gap: 1rem;
    }
  }
}

.techButton {
  text-align: start;
  min-width: 300px;
  max-width: max-content;
  justify-content: start;
  background: white;
  border: 1px solid black;
  color: black;

  &:hover {
    color: white;
    background: black;
  }

  @media (max-width: 1224px) {
    width: 100%;
    max-width: none;
  }
}
