@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.cardWrapper {
  position: relative;
  box-sizing: border-box;
  @include display(flex, row);

  @media (max-width: 1224px) {
    @include display(flex, column);
    width: 100%;
  }

  &_newsContent {
    @include display(flex, column, space-between);
    position: relative;
    padding: 0.5rem 0.75rem;

    &_upper {
      &_title {
        @include font(bold, $font-subtitle-size);
        color: black;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        text-decoration: none;

        &:hover {
          color: #cf2626;
        }
      }

      &_description {
        @include font(400, $font-default-size);
        color: rgb(107, 107, 107);
      }

      &_date {
        color: rgb(107, 107, 107);
        @include display(flex, row, left, center);
        gap: 0.25rem;
      }
    }

    &_lower {
      margin-top: 2rem;
      width: fit-content;
    }
  }

  &_cardImage {
    text-decoration: none;
    display: block;
    min-width: 300px;
    max-width: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;

    @media (max-width: 1224px) {
      max-width: none;
    }

    &::before {
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid rgba(255, 255, 255, 0);
      border-radius: 10px;
      z-index: 10;
    }

    &:hover {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        z-index: 10;
      }

      img {
        transform: scale(1.1) rotate(3deg);
        opacity: 0.8;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease 0s;
      vertical-align: center;
    }
  }
}
