@import '../src/pages/CarDetailsPage/customArrows.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --font-title-size: 28px;
  --font-default-size: 22px;
}
/* 
@font-face {
  font-family: "Roboto Flex", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap") format("truetype");
  font-display: swap;
} */

body {
  overflow-x: hidden;
  font-family: "Roboto Flex", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fefefe;
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    height: 100%;
  }
}

p,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
