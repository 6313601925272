@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.wrapper {
  max-width: 100vw;
  width: 100vw;
  position: relative;
  height: auto;

  a,
  a:focus,
  a:active {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
  }

  img {
    object-fit: cover;

    @media (max-width: 456px) {
      height: 55vw;
    }
  }
}

.soloImage {
  width: 100%;
  height: auto;
  object-fit: cover;

  @media (max-width: 456px) {
    height: 55vw;
  }
}

.imageBox {
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 456px) {
    height: 55vw;
  }

  &_textBox {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;

    @media screen and (max-width: 800px) {
      position: relative;
      color: #000000;
      transform: none;
      right: 0;
      align-items: flex-start;
      padding: 1rem 2rem;
    }

    &_title {
      font-size: $font-title-size;
      font-weight: bold;
    }

    &_description {
      font-size: $font-subtitle-size;
      font-weight: 600;
    }
  }
}
