@import "../../../../scss/mixin.scss";
@import "../../../../scss/variable.scss";

.buttonWrapper {
  height: 100%;
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: center;
  @include font(bold, $font-default-size);
  color: rgba(255, 255, 255);
  transition: ease-in 0.4s;
  border-bottom: 4px solid transparent;
  z-index: 30;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-bottom: 4px solid #cf2626;
  }
}
