@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.autoCard {
  transition: ease-in 0.3s;
  cursor: pointer;
  border: 1px solid #f3f3f3;

  &_buttonsBlock {
    @include display(flex, center, center);
    width: 100%;
    padding: 0 3rem;
    text-decoration: none;

    &_detailsButton {
      margin: 15px auto 0;
      text-align: center;
      height: 2.5rem;
      font-size: $font-default-size;
      color: #fff;
      display: block;
      text-decoration: none;
      transition: ease-in 0.3s;

      &:visited {
        text-decoration: none;
      }
    }
  }

  img {
    transition: ease-in 0.3s;
    width: 100%;
    object-fit: cover;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
