@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.headerWrapper {
  @include display(flex, row, space-around, center);
  z-index: 95;
  position: relative;

  &_header {
    @include position_t_l(fixed, 0, 0, 20);
    @include display(flex, row, space-between, center);
    margin: 0 auto;
    gap: 1em;
    width: 100%;
    height: 74px;
    background: rgba(0, 0, 0, 0.15);
    flex-wrap: wrap;
    padding-right: 3em;
    transition: ease-in 0.4s;

    &_logo {
      display: flex;
      vertical-align: middle;
      margin-left: 2rem;
      padding: 10px;
      color: #fff;
      font-size: $font-subtitle-size;
      font-weight: bold;
      text-decoration: none;
      max-height: 74px;
      max-width: 180px;

      a,
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: fill;
      }
    }
    &_buttonsBlock {
      @include display(flex, row, flex-start, center);
      gap: 2.5em;
      height: 100%;
      text-align: center;

      @media (max-width: 1224px) {
        display: none;
      }
    }

    &_buttonsBlock_mobile {
      @include display(flex, row, flex-start, center);
      gap: 2.5em;
      height: 100%;
      text-align: center;

      @media (min-width: 1224px) {
        display: none;
      }
    }
  }
}

@media (max-width: 1224px) {
  .headerWrapper {
    &_header {
      height: 4rem;
      padding: 0;

      &_logo {
        margin-left: 1rem;
      }
    }
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.dropdownMenu {
  display: block;
  position: absolute;
  width: 100vw;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.5rem;
  opacity: 0;
  transform: translateY(-10px);
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    display: block;
    padding: 0.25rem;
    text-decoration: none;
    &:hover {
      background-color: #ddd;
    }
  }
}

.open {
  animation-name: slideDown;
}

.closed {
  animation-name: slideUp;
  pointer-events: none;
}

.scrolledBg {
  background: rgb(45, 45, 45);
}

.mobileMenuWrapper {
  display: none;
  transition: ease-in 0.3s;
  height: calc(100dvh - 4rem);
  width: 100vw;
  position: fixed;
  top: 4rem;
  right: 0;
  z-index: 30;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(45, 45, 45, 1);
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transition: ease-in-out 0.5s;
  border-top: rgba(205, 208, 210, 0.4);

  &_accordionButton {
    @include font(bold, $font-subtitle-size);
    color: white !important;
    background: rgba(45, 45, 45, 1) !important;
    min-height: 4rem;
    border-bottom: 1px solid rgba(205, 208, 210, 0.4);
    text-decoration: none;
    box-shadow: none !important;

    &_link {
      @include display(flex, row, space-between);
      @include font(bold, $font-default-size);
      align-items: center;
      color: white;
      padding: 0 1rem;
      height: 3rem;
      // border-bottom: 1px solid rgba(205, 208, 210, 0.4);
      text-decoration: none;
    }
  }

  &_button {
    @include display(flex, row, space-between);
    @include font(bold, $font-subtitle-size);
    align-items: center;
    color: white;
    padding: 0 1rem;
    height: 4rem;
    border-bottom: 1px solid rgba(205, 208, 210, 0.4);
    text-decoration: none;
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  90% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    display: none;
    transform: translateX(100%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.mobileMenuOpen {
  display: block;
  animation-name: slideLeft;
}

.mobileMenuClose {
  animation-name: slideRight;
  display: block;
}

.dropdownWrapper {
  position: relative;
  display: inline-block;
  height: 100%;
  @include display(flex, column, center, center);
  &:hover .dropdown_content {
    animation-name: slideDown;
    display: block;
  }
}

.dropdown_content {
  transform: translateY(-10px);
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  display: none;
  position: absolute;
  text-align: left;
  top: 100%;
  background-color: #cf2626;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0.35%;

  a {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    @include font(bold, $font-small-size);
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #ffffff;
      color: rgb(103, 103, 103);
    }
  }
}
