@mixin display($display, $flex-direction: row, $justify-content: flex-start, $align-item: stretch) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin position_t_l($position, $top, $left, $z-index: 0) {
  position: $position;
  top: $top;
  left: $left;
  z-index: $z-index;
}

@mixin position_b_l($position, $bottom, $left, $z-index: 0) {
  position: $position;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
}

@mixin position_t_r($position, $top, $right, $z-index: 0) {
  position: $position;
  top: $top;
  right: $right;
  z-index: $z-index;
}

@mixin position_b_r($position, $bottom, $right, $z-index: 0) {
  position: $position;
  bottom: $bottom;
  right: $right;
  z-index: $z-index;
}

@mixin border($width, $style, $color) {
  border: $width $style $color;
}

@mixin font($weight, $size, $line-height: normal) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
}
