@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.dealersWrapper {
  p {
    @include font(400, $font-default-size);
  }

  &_title {
    display: flex;
    display: -moz-flex;
    justify-content: center;
    justify-content: -moz-center;
    align-items: center;
    align-items: -moz-center;
    flex-direction: column;
    padding: 1rem 0;

    @media (max-width: 1224px) {
      padding: 0.5rem 0;
    }

    h1 {
      font-size: $font-title-size;
      font-weight: bold;
      border-bottom: 3px solid #cf2626;
    }
  }

  &_content {
    @include display(flex, row, space-between, flex-start);
    flex-wrap: nowrap;
    max-width: 1600px;
    margin: 0 auto;
    padding: 1rem 5rem;
    gap: 50px;

    @media (max-width: 1224px) {
      flex-direction: column;
      padding: 1rem 1.5rem;
    }
    
    &_leftSide {
      max-width: 650px;
      @include display(flex, column, flex-start, center);
      gap: 50px;

      &_dealerData {
        @include display(flex, column, center, flex-start);
        width: 100%;
        gap: 1rem;

        img {
          padding: 2rem 0 1rem;
        }

        &_buttons {
          @include display(flex);
          gap: 1rem;

          @media (max-width: 1224px) {
            flex-wrap: wrap;
          }
        }
      }
    }

    &_rightSide {
      position: sticky;
      top: 11%;
      align-self: flex-start;

      &_mapWrapper {
        &_map {
          width: 50vw;
          height: 87vh;
          max-width: 800px;
          max-height: 1200px;

          @media (max-width: 1024px) {
            width: 90dvw;
            height: 50dvh;
          }
        }
      }
    }
  }
}
