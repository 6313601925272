@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.hotWrapper {
  width: 100%;
  box-sizing: border-box;
  transition: all ease 0.5s;

  &_link {
    display: block;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: inherit;
    border-radius: 10px;

    &:hover {
      img {
        transform: scale(1.03);
      }
    }

    img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      transition:
        transform 0.5s cubic-bezier(0.75, 0.02, 0.5, 1),
        -webkit-transform 0.5s cubic-bezier(0.75, 0.02, 0.5, 1);

      &:hover {
        transform: scale(1.2);
      }
    }

    &:visited {
      text-decoration: none;
    }
  }

  &_text {
    background: rgba(0, 0, 0, 0.4);
    font-size: $font-small-size;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s ease 0s;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;

    &:hover {
      p {
        color: #ffffff;
      }
    }

    p {
      padding: 1rem;
      color: #e6e6e6;
      font-weight: 600;
      font-size: $font-default-size;
      transition: all ease 0.2s;
    }
  }
}
