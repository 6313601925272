@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.container {
  max-width: clamp(25vw, 25vw, 40rem);
  height: 550px;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  cursor: pointer;
  transition: ease-in 0.2s;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 45%;
  }

  &_description {
    height: 100%;
    padding: 0.5rem 1.5rem 4rem;
    position: relative;
    height: 55%;

    &_title {
      @include font(bold, $font-subtitle-size);
      padding-bottom: 0.5rem;
      color: #cf2626;

      @media (max-width: 1224px) {
        @include font(bold, 20px);
      }
    }

    &_text {
      color: rgb(110, 110, 110);
      @include font(400, $font-default-size);
    }

    &_date {
      @include display(flex, row, left, center);
      position: absolute;
      bottom: 2rem;
      left: 1.5rem;
      text-decoration: none;
      gap: 5px;
      color: rgba(0, 0, 0, 0.4);

      @media (max-width: 1224px) {
        bottom: 1.2rem;
      }
    }
  }
}

@media (max-width: 1224px) {
  .container {
    max-width: 25rem;
    height: 450px;
  }
}
