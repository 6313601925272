@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.container {
  width: 100vw;
  height: auto;
  @include display(flex, column, center);
  gap: 2em;
  overflow-x: hidden;

  &_mainPageText {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (max-width: 1024px) {
      padding: 2rem 1rem;
    }

    &_title {
      text-align: center;
      font-size: $font-title-size;
      font-weight: bold;
    }

    &_description {
      color: black;
      font-size: $font-default-size;
    }
  }

  &_news {
    &_title {
      font-size: $font-title-size;
      font-weight: bold;
      justify-self: center;
      text-align: center;
    }
    @include display(flex, row, center, center);
    padding: 3rem;
    gap: 2rem;
    max-width: 1600px;
  }

  &_carousel {
    max-width: 100vw;
    height: auto;
    min-width: 100vw;

    img {
      min-width: 100vw;
      width: 100%;
      height: auto;
      object-fit: fill;
    }
  }

  &_carSelector {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    @include display(flex, column, center);
    gap: 1em;

    &_title {
      font-weight: bold;
      font-size: $font-title-size;
      justify-self: center;
      text-align: center;
    }

    &_models {
      @include display(flex, row, center);
      gap: 0.5em;

      &_carModel {
        font-size: $font-small-size;
        padding: 0.6em;
        text-align: center;
        color: rgb(0, 0, 0);
        transition: ease-in 0.4s;
        cursor: pointer;
        border-top: 2px solid transparent;

        @media (min-width: 1224px) {
          &:hover {
            border-top: 2px solid black;
          }
        }

        &_active {
          border-top: 2px solid black;
        }
      }
    }

    &_modelPreview {
      @include display(flex, column, center);
      gap: 10px;
      padding-bottom: 2rem;
      max-width: 100vw;
      width: 100%;
      align-items: center;

      @media (max-width: 1224px) {
        max-height: 600px;
      }

      &_slider {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 30vw;
        max-height: 30vw;
        width: 100%;
        flex-wrap: wrap;
        padding: 0 2rem;
      }
    }
  }
  color: #cf2626;
}

.newsWrapper {
  @include display(flex, column, center, center);
  background: rgb(237, 237, 237);
  padding: 2rem 1rem;
}

.homePageTextWrapperBlock {
  background: rgb(237, 237, 237);
  @include display(flex, column, center, center);
  padding: 2rem 1rem;
}

.moreButton {
  justify-self: center;
  padding-bottom: 0.15rem;
  border-bottom: 2px solid rgb(237, 237, 237);
  transition: ease-in 0.2s;
  cursor: pointer;
  font-size: $font-default-size;
  font-weight: bold;
  text-decoration: none;
  color: #cf2626;

  &:hover {
    border-bottom: 2px solid red;
  }
}

@media (max-width: 1224px) {
  .container {
    &_news {
      @include display(flex, column, center, center);
      padding: 1rem 0;
    }
    &_carSelector {
      &_modelPreview {
        &_slider {
          height: 60vw;
          max-height: 60vw;
        }
      }
    }
  }

  .modelCarDetail {
    font-weight: bold;
    font-size: $font-default-size;
    text-decoration: none;
  }
}

.swiper {
  @include display(flex, center, center);
  max-width: 100vw;
}

.miniBlocksWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 400px));
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  gap: 2rem;
  padding: 2rem 3rem;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    padding: 2rem 1rem;
  }
}
