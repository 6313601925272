@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.wrapper {
  @include display(flex, column, center, center);
  background: rgb(45, 45, 45);
  width: 100vw;
  max-width: 100vw;
  height: auto;
  padding: 2em 4em;
  z-index: 96;
  position: relative;
}

.legalInformation {
  @include display(flex, row, space-between, flex-start);
  font-size: 12px;
  color: white;
  width: 100vw;
  max-width: 100vw;
  padding: 2em 4em;

  p {
    line-height: 18px;
  }

  &_descr {
    max-width: 60vw;

    @media (max-width: 1224px) {
      max-width: 100%;
    }
  }

  @media (max-width: 1224px) {
    @include display(flex, column, center, flex-start);
    padding: 2rem 2rem 0;
  }
}

.footerWrapper {
  @include display(flex, row, space-between, flex-start);
  width: 100vw;
  max-width: 100vw;
  height: auto;
  padding: 2em 4em;
  gap: 2em;

  &_linkBar {
    @include display(flex, column, flex-start);
    gap: 0.5rem;

    &_title {
      color: rgb(255, 255, 255);
      @include font(600, $font-subtitle-size);
    }

    &_link {
      color: rgb(198, 198, 198);
      padding: 0 0 0.2rem 0;
      @include font(400, $font-default-size);
      width: max-content;
      text-decoration: none;
      cursor: pointer;
      border-bottom: 2px solid rgb(45, 45, 45);
      transition: ease-in 0.3s;

      &:hover {
        border-bottom: 2px solid rgb(198, 198, 198);
      }
    }
  }

  &_infoBar {
    @include display(flex, column, flex-start);
    width: 20.5rem;

    gap: 1.5rem;

    &_logo {
      max-width: 250px;
      object-fit: contain;

      @media (max-width: 1224px) {
        max-width: 100%;
      }
    }

    &_contacts {
      @include display(flex, row, center);
      align-items: center;
      color: rgb(255, 255, 255);
      padding: 0 0 0.2rem 0;
      font-size: $font-subtitle-size;
      font-weight: 600;
      width: max-content;
      cursor: pointer;
      border-bottom: 2px solid rgb(45, 45, 45);
      transition: ease-in 0.3s;
      gap: 0.4rem;
      text-decoration: none;

      &:hover {
        border-bottom: 2px solid rgb(198, 198, 198);
      }
    }

    &_contactInfo {
      @include display(flex, row, flex-start);
      align-items: center;
      color: rgb(255, 255, 255);
      font-size: $font-subtitle-size;
      font-weight: 600;
      gap: 0.4rem;

      &_icon {
        cursor: pointer;
        transition: ease-in 0.3s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media (max-width: 1224px) {
  .footerWrapper {
    @include display(flex, column, center, flex-start);
    padding: 0 2rem;

    &_linkBar {
      @include display(flex, column, center);
    }
  }
}
